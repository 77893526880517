import { useAccountInfo } from '@/contexts/AccountInfoContext';

export default function Home() {
  const { accountSlug } = useAccountInfo();

  if (typeof window !== 'undefined')
    window.location.assign(
      `${process.env.NEXT_PUBLIC_ROLLBAR_BASE_URL}/${accountSlug}`
    );

  return <div />;
}
